enum ColorsType {
  brand1 = "--theme-color-brand1",
  brand2 = "--theme-color-brand2",
  brand3 = "--theme-color-brand3",
  brand4 = "--theme-color-brand4",
  secondary1 = "--theme-color-secondary1",
  secondary2 = "--theme-color-secondary2",
  secondary3 = "--theme-color-secondary3",
  secondary4 = "--theme-color-secondary4",
  base1 = "--theme-color-base1",
  base2 = "--theme-color-base2",
  base3 = "--theme-color-base3",
  base4 = "--theme-color-base4",
  base5 = "--theme-color-base5",
  accent1 = "--theme-color-accent1",
  accent2 = "--theme-color-accent2",
  transparent = "--theme-transparent-color",
}

enum PaddingButtonType {
  small = "10px 16px",
  medium = "12px 20px",
  large = "16px 28px",
}

enum PaddingInputType {
  small = "6px 10px",
  medium = "8px 12px",
  large = "10px 14px",
}

enum BorderRadiusType {
  sharp = "0px",
  rounded = "8px",
  pill = "999px",
}

export const getGlobalStyle = (theme) => {
  return `
  .button-view-primary {
    background-color: var(${
      ColorsType[theme?.colors?.buttons?.primary?.default?.background] ||
      theme?.colors?.buttons?.primary?.default?.background
    });
    border: ${theme?.buttons?.primary?.border?.width} solid
      var(${ColorsType[theme?.colors?.buttons?.primary?.default?.border]});
    padding: ${PaddingButtonType[theme?.buttons?.primary?.padding]};
    border-start-start-radius: ${
      theme?.buttons?.primary?.border?.borderStartStartRadius
    }px;
    border-start-end-radius: ${
      theme?.buttons?.primary?.border?.borderStartEndRadius
    }px;
    border-end-start-radius: ${
      theme?.buttons?.primary?.border?.borderEndStartRadius
    }px;
    border-end-end-radius: ${
      theme?.buttons?.primary?.border?.borderEndEndRadius
    }px;
    &:hover {
      background-color: var(
        ${ColorsType[theme?.colors?.buttons?.primary?.hover?.background]}
      );
      * {
        color: var(
          ${ColorsType[theme?.colors?.buttons?.primary?.hover?.text]}
          );
      }
      border: ${theme?.buttons?.primary?.border?.width} solid
        var(${ColorsType[theme?.colors?.buttons?.primary?.hover?.border]});
        }
    }
 .button-view-primary, .button-view-primary * {
 color: var(${ColorsType[theme?.colors?.buttons?.primary?.default?.text]});
 }

.button-view-secondary {
    background-color: var(${
      ColorsType[theme?.colors?.buttons?.secondary?.default?.background]
    });
    border: ${theme?.buttons?.secondary?.border?.width} solid
      var(${ColorsType[theme?.colors?.buttons?.secondary?.default?.border]});
    padding: ${PaddingButtonType[theme?.buttons?.secondary?.padding]};
   border-start-start-radius: ${
     theme?.buttons?.secondary?.border?.borderStartStartRadius
   }px;
    border-start-end-radius: ${
      theme?.buttons?.secondary?.border?.borderStartEndRadius
    }px;
    border-end-start-radius: ${
      theme?.buttons?.secondary?.border?.borderEndStartRadius
    }px;
    border-end-end-radius: ${
      theme?.buttons?.secondary?.border?.borderEndEndRadius
    }px;
    &:hover {
      background-color: var(
        ${ColorsType[theme?.colors?.buttons?.secondary?.hover?.background]}
      );
      * {
        color: var(
          ${ColorsType[theme?.colors?.buttons?.secondary?.hover?.text]}
          );
      }
      border: ${theme?.buttons?.secondary?.border?.width} solid
        var(${ColorsType[theme?.colors?.buttons?.secondary?.hover?.border]});
    }
}

.button-view-secondary, .button-view-secondary * {
 color: var(${ColorsType[theme?.colors?.buttons?.secondary?.default?.text]});
 }

.button-view-tertiary {
    background-color: var(${
      ColorsType[theme?.colors?.buttons?.tertiary?.default?.background]
    });
            color: var(
      ${ColorsType[theme?.colors?.buttons?.tertiary?.default?.text]}
    );
    border: ${theme?.buttons?.tertiary?.border?.width} solid
    var(${ColorsType[theme?.colors?.buttons?.tertiary?.default?.border]});
    padding: ${PaddingButtonType[theme?.buttons?.tertiary?.padding]};
     border-start-start-radius: ${
       theme?.buttons?.tertiary?.border?.borderStartStartRadius
     }px;
    border-start-end-radius: ${
      theme?.buttons?.tertiary?.border?.borderStartEndRadius
    }px;
    border-end-start-radius: ${
      theme?.buttons?.tertiary?.border?.borderEndStartRadius
    }px;
    border-end-end-radius: ${
      theme?.buttons?.tertiary?.border?.borderEndEndRadius
    }px;
    &:hover {
      background-color: var(
        ${ColorsType[theme?.colors?.buttons?.tertiary?.hover?.background]}
      );
      * {
        color: var(
          ${ColorsType[theme?.colors?.buttons?.tertiary?.hover?.text]}
          );
      }
      border: ${theme?.buttons?.tertiary?.border?.width} solid
        var(${ColorsType[theme?.colors?.buttons?.tertiary?.hover?.border]});
    }
}

.button-view-tertiary, .button-view-tertiary * {
 color: var(${ColorsType[theme?.colors?.buttons?.tertiary?.default?.text]});
 }

#global-heading-1-styles, #global-heading-1-styles * {
    color: var(${ColorsType[theme?.colors?.text?.heading1]});
 }

#global-heading-2-styles, #global-heading-2-styles * {
    color: var(
            ${ColorsType[theme?.colors?.text?.heading2]}
            );
}

#global-heading-3-styles, #global-heading-3-styles * {
    color: var(
            ${ColorsType[theme?.colors?.text?.heading3]}
            );
}

#global-heading-4-styles, #global-heading-4-styles * {
    color: var(
            ${ColorsType[theme?.colors?.text?.heading4]}
            );
 }

#global-heading-5-styles, #global-heading-5-styles * {
    color: var(
            ${ColorsType[theme?.colors?.text?.heading5]}
            );
 }

#global-heading-6-styles, #global-heading-6-styles * {
    color: var(
            ${ColorsType[theme?.colors?.text?.heading6]}
            );
 }

#global-paragraph-1-styles, #global-paragraph-1-styles * {
    color: var(
    ${ColorsType[theme?.colors?.text?.paragraph1]}
            );
 }
#global-paragraph-2-styles, #global-paragraph-2-styles * {
    color: var(
    ${ColorsType[theme?.colors?.text?.paragraph2]}
            );
 }

 #global-paragraph-3-styles, #global-paragraph-3-styles * {
    color: var(
    ${ColorsType[theme?.colors?.text?.paragraph3]}
            );
 }

.general-input-wrapper-view {
    background-color:  var(${
      theme?.form?.fill
        ? ColorsType[theme?.colors?.formField?.fill]
        : "--white-background-color"
    });
    color: var(${ColorsType[theme?.colors?.formField?.text]});
    border: 1px ${theme?.form?.border?.style}
      var(${ColorsType[theme?.colors?.formField?.border]});
    padding: ${PaddingInputType[theme?.form?.size]};
    border-radius: ${BorderRadiusType[theme?.form?.style]};
}
.general-input-label {
  color: var(${ColorsType[theme?.colors?.formField?.label]});
}
      `;
};
